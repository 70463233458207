import { UAParser } from 'ua-parser-js'

const parser = new UAParser(window.navigator.userAgent)
const { type } = parser.getDevice()

export const userAgent = parser.getResult()

export const isMobile = type === 'mobile' || type === 'tablet'

export const isMobileBrowse = (): boolean => {
  let isMobile = false
  if (navigator.userAgent) {
    isMobile = Boolean(navigator.userAgent.match(/Android|iPhone|iPad|IEMobile|WPDesktop/i))
  }
  return isMobile
}
