export interface InitialState {
  apply: boolean
  creatorNames: string
  email: string
  collectionName: string
  description: string
  engine: string
  website: string
  socialHandle: string
  fileName: string
  artUri: string
  acceptPriceAck: boolean
  acceptOriginalityAck: boolean
}

export const initialState: InitialState = {
  apply: false,
  creatorNames: '',
  email: '',
  collectionName: '',
  description: '',
  engine: '',
  website: '',
  socialHandle: '',
  fileName: '',
  artUri: '',
  acceptPriceAck: false,
  acceptOriginalityAck: false
}
