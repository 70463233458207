import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from 'state/submitartwork/types'

const submitArtworkSlice = createSlice({
  name: 'submitArtwork',
  initialState,
  reducers: {
    setApply(state, action: PayloadAction<boolean>) {
      state.apply = action.payload
    },
    setAcceptPriceAck(state, action: PayloadAction<boolean>) {
      state.apply = action.payload
    },
    setAcceptOriginalityAck(state, action: PayloadAction<boolean>) {
      state.apply = action.payload
    },
    setCreatorNames(state, action: PayloadAction<string>) {
      state.creatorNames = action.payload
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload
    },
    setCollectionName(state, action: PayloadAction<string>) {
      state.collectionName = action.payload
    },
    setDescription(state, action: PayloadAction<string>) {
      state.description = action.payload
    },
    setEngine(state, action: PayloadAction<string>) {
      state.engine = action.payload
    },
    setWebsite(state, action: PayloadAction<string>) {
      state.website = action.payload
    },
    setSocialHandle(state, action: PayloadAction<string>) {
      state.socialHandle = action.payload
    },
    setArtFileName(state, action: PayloadAction<string>) {
      state.fileName = action.payload
    },
    setArtUri(state, action: PayloadAction<string>) {
      state.artUri = action.payload
    },
    resetSubmitArtworkReduxState(state) {
      state.apply = false
      state.creatorNames = ''
      state.email = ''
      state.collectionName = ''
      state.description = ''
      state.engine = ''
      state.website = ''
      state.socialHandle = ''
      state.fileName = ''
      state.artUri = ''
      state.acceptPriceAck = false
      state.acceptOriginalityAck = false
    }
  }
})

export const {
  setApply,
  setAcceptPriceAck,
  setAcceptOriginalityAck,
  setCreatorNames,
  setEmail,
  setCollectionName,
  setDescription,
  setEngine,
  setWebsite,
  setSocialHandle,
  setArtFileName,
  setArtUri,
  resetSubmitArtworkReduxState
} = submitArtworkSlice.actions
export default submitArtworkSlice.reducer
