import socionc1 from 'assets/images/home-socicon1_1home-socicon1.png'
import socionc2 from 'assets/images/home-socicon2_1home-socicon2.png'
import socicon3 from 'assets/images/home-socicon3_1home-socicon3.png'
import socionc4 from 'assets/images/Icon-awesome-discord.png'
import { useHistory } from 'react-router-dom'
import { ExternalLink } from 'theme'

export default function CustomFooter() {
  const history = useHistory()
  return (
    <div className="wrap w50-spcbetween">
      <div
        onClick={() => {
          history.push('/')
        }}
        aria-current="page"
        className="link-dark"
      >
        aw3c
      </div>
      <div className="text-block-16">Copyright © 2022 AW3C. All rights reserved.</div>
      <div className="home-footer-socicons-wrap">
        <ExternalLink href="https://twitter.com/AW3Cxyz" className="home-footer-socicon-wrap w-inline-block">
          <img src={socionc1} width="20" alt="" className="home-footer-socicon" />
        </ExternalLink>
        <ExternalLink href="https://discord.gg/aw3c" className="home-footer-socicon-wrap w-inline-block">
          <img src={socionc4} width="20" alt="" className="home-footer-socicon" />
        </ExternalLink>
        <ExternalLink href="https://www.instagram.com/aw3c.xyz" className="home-footer-socicon-wrap w-inline-block">
          <img src={socionc2} width="20" alt="" className="home-footer-socicon" />
        </ExternalLink>
        <ExternalLink
          href="https://www.youtube.com/channel/UCcuVuZDbNdXhWI2Bf1oWi5A"
          className="home-footer-socicon-wrap home-footer-socicon-wrap-last w-inline-block"
        >
          <img src={socicon3} width="20" alt="" className="home-footer-socicon" />
        </ExternalLink>
      </div>
    </div>
  )
}
