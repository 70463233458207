import { AutoColumn } from 'components/Column'
import { AutoRow } from 'components/Row'
import { useContext } from 'react'
import { AlertCircle } from 'react-feather'
import styled, { ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`

export default function ErrorPopup({ errorInfo }: { errorInfo: string }) {
  const theme = useContext(ThemeContext)

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        <AlertCircle color={theme.red1} size={24} />
      </div>
      <AutoColumn gap="8px">
        <ThemedText.Body fontWeight={500}>{errorInfo}</ThemedText.Body>
      </AutoColumn>
    </RowNoFlex>
  )
}
