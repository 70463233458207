import { useWeb3React } from '@web3-react/core'
import { Connector } from '@web3-react/types'
import FortmaticIcon from 'assets/images/fortmaticIcon.png'
import CoinbaseWalletIcon from 'assets/svg/coinbaseWalletIcon.svg'
import WalletConnectIcon from 'assets/svg/walletConnectIcon.svg'
import Identicon from 'components/Identicon'
import { coinbaseWallet, fortmatic, injected, walletConnect } from 'connectors'

export default function StatusIcon({ connector }: { connector: Connector }) {
  const { account } = useWeb3React()
  switch (connector) {
    case injected:
      return <Identicon account={account} />
    case walletConnect:
      return <img src={WalletConnectIcon} alt="WalletConnect" />
    case coinbaseWallet:
      return <img src={CoinbaseWalletIcon} alt="Coinbase Wallet" />
    case fortmatic:
      return <img src={FortmaticIcon} alt="Fortmatic" />
    default:
      return null
  }
}
