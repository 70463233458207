import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import multicall from 'lib/state/multicall'
import { load, save } from 'redux-localstorage-simple'
import application from 'state/application/reducer'
import { daoApi } from 'state/daos/slice'
import { api as dataApi } from 'state/data/slice'
import { updateVersion } from 'state/global/actions'
import logs from 'state/logs/slice'
import submitArtwork from 'state/submitartwork/slice'
import transactions from 'state/transactions/reducer'
import user from 'state/user/reducer'
import wallet from 'state/wallet/reducer'

const PERSISTED_KEYS: string[] = ['user', 'transactions', 'submitArtwork']

const store: any = configureStore({
  reducer: {
    application,
    user,
    wallet,
    transactions,
    multicall: multicall.reducer,
    logs,
    submitArtwork,
    [dataApi.reducerPath]: dataApi.reducer,
    [daoApi.reducerPath]: daoApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true })
      .concat(dataApi.middleware)
      .concat(daoApi.middleware)
      .concat(save({ states: PERSISTED_KEYS, debounce: 1000 })),
  preloadedState: load({ states: PERSISTED_KEYS, disableWarnings: process.env.NODE_ENV === 'test' })
})

store.dispatch(updateVersion())

setupListeners(store.dispatch)

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
