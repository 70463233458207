import { Connector } from '@web3-react/types'
import { coinbaseWallet, fortmatic, injected, Wallet, walletConnect } from 'connectors'

interface WalletInfo {
  connector?: Connector
  wallet?: Wallet
  name: string
  iconURL: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    wallet: Wallet.INJECTED,
    name: 'Injected',
    iconURL: '/svg/arrow-right.svg', // INJECTED_ICON_URL,
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    wallet: Wallet.INJECTED,
    name: 'MetaMask',
    iconURL: '/images/metamask.png', //METAMASK_ICON_URL,
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletConnect,
    wallet: Wallet.WALLET_CONNECT,
    name: 'WalletConnect',
    iconURL: '/svg/walletConnectIcon.svg',
    // WALLETCONNECT_ICON_URL,
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  COINBASE_WALLET: {
    connector: coinbaseWallet,
    wallet: Wallet.COINBASE_WALLET,
    name: 'Coinbase Wallet',
    iconURL: '/svg/coinbaseWalletIcon.svg',
    //COINBASE_ICON_URL,
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconURL: '/svg/coinbaseWalletIcon.svg',
    //COINBASE_ICON_URL,
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  },
  FORTMATIC: {
    connector: fortmatic,
    wallet: Wallet.FORTMATIC,
    name: 'Fortmatic',
    iconURL: '/images/fortmaticIcon.png',
    // FORTMATIC_ICON_URL,
    description: 'Login using Fortmatic hosted wallet',
    href: null,
    color: '#6748FF',
    mobile: true
  }
}
