import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
// import type { AxiosError, AxiosRequestConfig } from 'axios'
// import axios from 'axios'
import { SupportedChainId } from 'constants/chains'
import store, { AppState } from 'state'
import { updateLogout } from 'state/application/reducer'
import { ResponseBackend } from 'state/daos/types'

export const CHAIN_API_URL: Record<number, string> = {
  [SupportedChainId.MAINNET]: 'https://api.aw3c.xyz',
  [SupportedChainId.RINKEBY]: 'https://api.test.aw3c.xyz'
}

function getBaseUri() {
  return CHAIN_API_URL[store?.getState()?.application?.chainId ?? SupportedChainId.MAINNET]
}

const baseQuery = fetchBaseQuery({
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as AppState)?.user?.userInfo ? (getState() as AppState)?.user?.userInfo?.accessToken : ''

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('authorization', `${token}`)
    }
    headers.set('Access-Control-Allow-Origin', '*')

    return headers
  }
})
const baseQueryWithAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions)
  if (result.error && (result.error.status === 401 || result.error.status === 403)) {
    if (store?.getState()?.application) {
      store.dispatch(updateLogout({ logout: true }))
    }
  }
  return result
}

export const daoApi: any = createApi({
  reducerPath: 'daoApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (build) => ({
    loginBySigned: build.mutation<
      ResponseBackend,
      {
        address: string
        message: string
        signature: string
      }
    >({
      query: (body: any) => ({
        url: `${getBaseUri()}/user/v1/login/ethereum/manager`,
        method: 'POST',
        body
      })
    }),
    uploadFile: build.mutation<any, any>({
      query: (body: { url: string; body: any }) => ({
        url: body.url,
        method: 'PUT',
        body: body.body
      })
    }),
    submitArtwork: build.mutation<any, any>({
      query: (body: any) => ({
        url: `${getBaseUri()}/nft/v1/project/submit`,
        method: 'POST',
        body
      })
    }),
    getPresign: build.mutation<any, any>({
      query: (params: any) => ({
        url: `${getBaseUri()}/nft/v1/project/presign`,
        method: 'GET',
        params
      })
    }),
    getDownloadPresign: build.mutation<any, any>({
      query: (params: any) => ({
        url: `${getBaseUri()}/nft/v1/project/presign/get`,
        method: 'GET',
        params
      })
    }),
    getProjects: build.mutation<API.RuleList, API.PageParams>({
      query: (params: API.PageParams) => ({
        url: `${getBaseUri()}/nft/v1/projects`,
        method: 'GET',
        params
      })
    }),
    updateProject: build.mutation<API.RuleList, any>({
      query: (body: any) => ({
        url: `${getBaseUri()}/nft/v1/project/update`,
        method: 'POST',
        body
      })
    }),
    approveProject: build.mutation<API.RuleList, any>({
      query: (body: any) => ({
        url: `${getBaseUri()}/nft/v1/project/approve`,
        method: 'POST',
        body
      })
    }),
    deployedProject: build.mutation<API.RuleList, { projectId: number; projectIndex: string; launchTime: number }>({
      query: (body: { projectId: number; projectIndex: string; launchTime: number }) => ({
        url: `${getBaseUri()}/nft/v1/project/deployed`,
        method: 'POST',
        body
      })
    }),
    getRejectList: build.mutation<ResponseBackend, any>({
      query: () => ({
        url: `${getBaseUri()}/nft/v1/project/reject/list`,
        method: 'GET'
      })
    }),
    getBuildInfo: build.mutation<ResponseBackend, any>({
      query: (params) => ({
        url: `${getBaseUri()}/nft/v1/project/build/info`,
        method: 'GET',
        params
      })
    }),
    buildProject: build.mutation<API.RuleList, any>({
      query: (body: any) => ({
        url: `${getBaseUri()}/nft/v1/project/build`,
        method: 'POST',
        body
      })
    }),
    getProjectInfo: build.mutation<ResponseBackend, { projectId: number }>({
      query: (params) => ({
        url: `${getBaseUri()}/nft/v1/project/info`,
        method: 'GET',
        params
      })
    })
  })
})

export const {
  useLoginBySignedMutation,
  useUploadFileMutation,
  useSubmitArtworkMutation,
  useGetProjectsMutation,
  useGetPresignMutation,
  useUpdateProjectMutation,
  useDeployedProjectMutation,
  useApproveProjectMutation,
  useGetRejectListMutation,
  useGetBuildInfoMutation,
  useBuildProjectMutation,
  useGetProjectInfoMutation,
  useGetDownloadPresignMutation
} = daoApi
