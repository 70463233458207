import { BigNumberish } from '@ethersproject/bignumber'

interface SerializableTransactionReceipt {
  to: string
  from: string
  contractAddress: string
  transactionIndex: number
  blockHash: string
  transactionHash: string
  blockNumber: number
  status?: number
}

/**
 * Be careful adding to this enum, always assign a unique value (typescript will not prevent duplicate values).
 * These values is persisted in state and if you change the value it will cause errors
 */
export enum TransactionType {
  APPROVAL = 0,
  DELEGATE,
  BUILD,
  SAFE_MINT,
  NEW_TIMER,
  NEW_CONFIG
}

export interface BaseTransactionInfo {
  type: TransactionType
}

export interface DelegateTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.DELEGATE
  delegatee: string
}

export interface ApproveTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.APPROVAL
  tokenAddress: string
  spender: string
}

export interface BuildTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.BUILD
  projectId: number
  projectIndex: string
  launchTime: number
}

export interface SafeMintTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.SAFE_MINT
  to: string
}

export interface SetNewTimerTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.NEW_TIMER
  startTime: BigNumberish
  endTime: BigNumberish
}

export interface SetNewConfigTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.NEW_CONFIG
  startTime: BigNumberish
  endTime: BigNumberish
}

export type TransactionInfo =
  | ApproveTransactionInfo
  | DelegateTransactionInfo
  | BuildTransactionInfo
  | SafeMintTransactionInfo
  | SetNewTimerTransactionInfo
  | SetNewConfigTransactionInfo

export interface TransactionDetails {
  hash: string
  receipt?: SerializableTransactionReceipt
  lastCheckedBlockNumber?: number
  addedTime: number
  confirmedTime?: number
  from: string
  info: TransactionInfo
}
