import { namehash } from '@ethersproject/hash'

export function safeNamehash(name?: string): string | undefined {
  if (name === undefined) return undefined

  try {
    return namehash(name)
  } catch (error) {
    console.debug(error)
    return undefined
  }
}

export const shortAddress = (val: string | null | undefined = '', start: number, end: number): string => {
  if (!val) return ''
  return [val.slice(0, start), '...', val.slice(-end)].join('')
}
